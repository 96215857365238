<template>
  <div class="container">
    <div class="container-title">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        style="cursor: pointer"
        @click="handleRouterBack"
      >
      <div class="container-title-t">
        Detail Tiket
      </div>
    </div>
    <div
      class="rounded-xl ring-gray-300 mt-8 p-2 container-border"
      style="border: 1px solid #E2E2E2"
    >
      <div
        v-b-toggle.collapse-1
        class="flex mb-2"
      >
        <div class="flex justify-between w-full">
          <strong
            class="font-medium text-lg"
            style="color: #000000 !important;"
          >Detail Tiket</strong>
          <div v-if="dataDetailTiket.request_follow_up === 1" class="bagde-flag-priority">
            Request Follow up
          </div>
        </div>
        <span class="when-opened">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
            alt="Komerce"
            style="margin-left: 5px"
          >
        </span>
        <span class="when-closed">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
            alt="Komerce"
            style="margin-left: 5px"
          >
        </span>
      </div>
      <BCollapse
        id="collapse-1"
        :visible="true"
      >
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Status Tiket
            </p>
            <div
              class="status"
              v-bind="
                dataDetailTiket.ticket_status === 'Batal'
                  ? { class: 'cancel' }
                  : dataDetailTiket.ticket_status === 'Selesai'
                    ? { class: 'finish' }
                    : dataDetailTiket.ticket_status === 'Belum diproses'
                      ? { class: 'onProsess' }
                      : { class: 'needProsess' }
              "
            >
              {{ dataDetailTiket.ticket_status }}
            </div>
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Status Order
            </p>
            <div class="flex items-center gap-[8px]">
              <b-badge
                v-if="dataDetailTiket.order_status !== 'Rusak'"
                class="h-fit"
                :variant="orderStatusVariant(dataDetailTiket.order_status)"
                :style="
                  dataDetailTiket.order_status === 'Hilang'
                    ? 'background-color: #FCBEBE!important; color: #E31A1A!important'
                    : ''
                "
              >
                {{
                  dataDetailTiket.order_status === 'Hilang'
                    ? 'Paket Hilang'
                    : dataDetailTiket.order_status
                }}
              </b-badge>
              <b-badge
                v-if="dataDetailTiket.order_status === 'Rusak'"
                class="h-fit"
                :variant="orderStatusVariant(dataDetailTiket.order_status)"
                :style="
                  dataDetailTiket.order_status === 'Rusak'
                    ? 'background-color: #FCBEBE!important; color: #E31A1A!important'
                    : ''
                "
              >
                {{
                  dataDetailTiket.order_status === 'Rusak'
                    ? 'Paket Rusak'
                    : dataDetailTiket.order_status
                }}
              </b-badge>
              <div id="label__priority">
                <button
                  v-if="!loading"
                  :class="['icon p-[4px] rounded-full', { 'focus:outline-[#ea5455]': statusPriority === 1 }]"
                  @click="togglePriority"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/red-flag.svg"
                    alt="red flag"
                    :class="{ grayscale: statusPriority !== 1 }"
                  >
                </button>
                <b-popover
                  v-if="statusPriority === 1"
                  class="custom_arrowlabel"
                  triggers="hover"
                  placement="top"
                  target="label__priority"
                >
                  <span style="text-align: -webkit-center">
                    Ticket Prioritas
                  </span>
                </b-popover>
              </div>
              <b-spinner
                v-if="loading"
                class="loading-flag-priority"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Nomor Tiket
            </p>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="dataDetailTiket.ticket_no"
                disabled
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  class="cursor-pointer"
                  size="20"
                  @click="copyTicket(dataDetailTiket.ticket_no)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Nomor Resi
            </p>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="dataDetailTiket.no_resi"
                disabled
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  class="cursor-pointer"
                  size="20"
                  @click="copyTicket(dataDetailTiket.no_resi)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Seller
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.partner_name"
            />
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Buyer
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.customer_name"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Jenis Tiket
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.ticket_type"
            />
          </b-col>
          <b-col
            v-if="!dataDetailTiket.file"
            md="6"
          >
            <p class="font-bold">
              File
            </p>
            <a
              v-for="(item, index) in dataDetailTiket.file"
              :key="index"
              target="_blank"
              :href="item.path"
              class="download"
            >
              {{ handleCutUrl(item.path) }}
            </a>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Deskripsi
            </p>
            <b-form-textarea
              disabled
              :value="dataDetailTiket.description"
            />
          </b-col>
          <b-col
            md="6"
            style="margin-top: 10px"
          >
            <b-button
              variant="outline-primary"
              class="text-red-400 absolute bottom-0 right-8"
              @click="handleDetailOrder()"
            >
              Detail Order
            </b-button>
          </b-col>
        </b-row>
      </BCollapse>
    </div>

    <HistoryTiket
      v-if="dataDetailTiket.history_ticket.length > 0"
      :data="dataDetailTiket"
      :handleregeximage="handleRegexImage"
      :handleregexdoc="handleRegexDoc"
      :handelregexvideo="handelRegexVideo"
      :formatdate="formatDate"
      :handleextension="handleExtension"
      :handlecuturl="handleCutUrl"
      @executeParentMethod="initializeDetail"
    />
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import HistoryTiket from './HistoryTiket.vue'

export default {
  name: 'DetailTiket',
  components: {
    HistoryTiket,
    BCollapse,
  },
  data() {
    return {
      dataDetailTiket: {},
      statusPriority: 0,
      loading: false,
    }
  },
  mounted() {
    this.initializeDetail()
  },
  methods: {
    initializeDetail() {
      this.$http_komship
        .get(`/v1/ticket-admin/detail/${this.$route.params.id}`)
        .then(res => this.setDataDetailTiket(res.data.data))
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    setDataDetailTiket(data) {
      this.dataDetailTiket = data
      this.statusPriority = data.status_priority
    },
    copyTicket(data) {
      navigator.clipboard.writeText(data)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: `Copied the text ${data}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    handleDetailOrder() {
      this.$router.push({
        path: `/tiket/detail/order/${this.dataDetailTiket.order_id}`,
      })
    },
    handleRouterBack() {
      this.$router.back()
    },
    handleCutUrl(value) {
      return value.substring(value.lastIndexOf('/') + 1, value.length)
    },
    handleRegexImage(filename) {
      return /\.(png|svg|jpg|jpeg|gif|jfif)$/i.test(filename)
    },
    handleRegexDoc(filename) {
      return /\.(xlsx|xls|doc|docx|pdf)$/i.test(filename)
    },
    handelRegexVideo(filename) {
      return /\.(mp4)$/i.test(filename)
    },
    formatDate(value) {
      return moment(value)
        .format('D MMM YYYY | HH:mm')
    },
    handleExtension(value) {
      return value.split('.')
        .pop()
    },
    orderStatusVariant(data) {
      let result = ''
      if (data === 'Diajukan') {
        result = 'light-primary'
      } else if (data === 'Dipacking') {
        result = 'light-info'
      } else if (data === 'Dikirim') {
        result = 'light-warning'
      } else if (data === 'Diterima') {
        result = 'light-success'
      } else if (data === 'Retur') {
        result = 'light-danger'
      } else if (data === 'Batal') {
        result = 'light-dark'
      }
      return result
    },
    togglePriority() {
      this.loading = true

      const body = { status_priority: this.statusPriority === 0 ? 1 : 0, ticket_id: Number(this.$route.params.id) }

      this.$http_komship
        .post(`/v1/ticket-partner/set-priority/${this.$route.params.id}`, body)
        .then(() => {
          this.initializeDetail()
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: 'Gagal update status prioritas',
                variant: 'danger',
              },
            },
            2000,
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss" src="./DetailTiket.scss" />
